.App {
  text-align: left;
  padding: 10px;
}

h1{
  font-style: arial;
  font-size: 40px;
}

.App-logo {
  height: 40vmin;
}

.logo {
  position: absolute;
  height: 157px;
  width: 100%;
  background-image: url("melpro-header.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.headerContainer{
  position: absolute;
  line-height: 0;
  margin: 10px;
  padding: 5px;
  width: 500px;
  border: 0.5px solid;
  border-radius: 4px;
  right: 150px;
  bottom: 0px;

}

.mkvd-circle {
  width: 48px;
  height: 48px;
  border: 4px solid #666666;
  border-radius: 50%;
}
.mkvd-circle h1 {
  font-size: 40px;
  margin-top: -12px;
  margin-left: -1px;
  text-align: center;
  display: block;
}


.App-header {
  background-color: white;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

